.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 12px; 
  height: 12px; 
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* צבע הרקע של המסילה */
  border-radius: 10px; /* עיגול הפינות של המסילה */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* צבע הידית של הגלגלת */
  border-radius: 10px; /* עיגול הפינות של הידית */
  border: 3px solid #f1f1f1; /* גבול מסביב לידית */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* צבע הידית במצב ריחוף */
}

/* עיצוב לגלגלת עבור Firefox */
html {
  scrollbar-width: thin; /* עובי הגלגלת: "auto" או "thin" */
  scrollbar-color: #0D1E46 #f1f1f1; /* צבע הידית, צבע המסילה */
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #0D1E46;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.content {
  margin-top: 60px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: #f5f5f5;
}

.Accessibility {
  position: 'left'; /* הגדרת הקונטיינר כמיקום יחסית */
  width: 0; /* או גובה מוגדר, תלוי בתוכן שלך */
  height:14%; /* או גובה מוגדר */
}

